import { faArrowCircleLeft, faComments } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from '@sequensis/react-auth';
import { useConfig } from '@sequensis/react-config';
import { Flex, getColor, Icon, Text } from '@sequensis/stylish-core';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const linkStyles = css`
  /* Create a custom property to allow animating underline offset */
  @property --offset {
    syntax: '<length>';
    inherits: false;
    initial-value: 3px;
  }

  color: ${getColor('backgroundPair')};
  text-decoration: underline;
  text-decoration-color: ${getColor('background')};
  text-underline-offset: var(--offset);
  transition: --offset 200ms, text-decoration-color 200ms;

  &:hover {
    --offset: 5px;
    text-decoration-color: ${getColor('backroundPair')};
  }

  /* Fallback to only animating the colour for browsers that dont support @property */
  @supports not (background: paint(something)) {
    text-underline-offset: 5px;
    transition: text-decoration-color 200ms;

    &:hover {
      text-underline-offset: 5px;
    }
  }
`;

const A = styled.a<SpaceProps>`
  ${linkStyles}
  ${space}

  &:focus, &:focus-visible {
    outline: ${getColor('accessibility')} solid 4px;
  }
`;

export const HeaderNavigation = () => {
  const { supportHelpdesk } = useConfig();
  const { logout } = useAuth();

  return (
    <Flex
      as="nav"
      aria-label="Miscellaneous"
      flexDirection="row"
      flex="1"
      justifyContent="flex-end"
    >
      <A href={supportHelpdesk} target="_blank" rel="noopener noreferrer">
        <Icon icon={faComments} color="backgroundPair" />
        <Text ml={2} fontWeight="bold">
          Help &amp; support
        </Text>
      </A>

      <A
        onClick={(e) => {
          e.preventDefault();
          logout();
        }}
        href="/logout"
        ml={5}
      >
        <Icon icon={faArrowCircleLeft} color="backgroundPair" />
        <Text ml={2} fontWeight="bold">
          Log Out
        </Text>
      </A>
    </Flex>
  );
};
